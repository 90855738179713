/* eslint-disable no-unused-vars */
import {
  getPoiList,
  addPoi,
  displayObject,
  deletePoi,
  playVideo,
  videoTrack,
  // getVideoList,
  getObjectByTag,
} from "../api/passApi.js";
const llll = {
  宅基地: {
    resourceId: "ad0f4bf8-1e10-432a-afd8-86b1ba83272a",
    name: "POI_6",
  },
  党员: {
    resourceId: "5f67b947-a572-43cb-adb7-9f8fed9c61d3",
    name: "党员",
  },
  老年人: {
    resourceId: "926fde61-f44f-4fc1-87ff-4972372d89ca",
    name: "老年人",
  },
  微网格员: {
    resourceId: "5b498a67-70a2-45af-9ef3-b34cd1a51206",
    name: "网格员",
  },
  网格长: {
    resourceId: "94ae340b-3002-4e90-addc-87f9bc4df620",
    name: "网格长",
  },
  优抚对象: {
    resourceId: "74d51686-a3e5-44be-b13e-9ddac931afa8",
    name: "POI_26",
  },
  接种疫苗: {
    resourceId: "13f9e049-074c-4ccb-9025-897e3295e58d",
    name: "POI_27",
  },
  残疾人: {
    resourceId: "13f9e049-074c-4ccb-9025-897e3295e58d",
    name: "POI_27",
  },
  垃圾分类: {
    resourceId: "61ca5448-1714-4737-82ce-e57480c6eb8e",
    name: "POI_7",
  },
  污水处理: {
    resourceId: "69c5439f-a1ac-4077-865e-cb47ac158826",
    name: "POI_8",
  },
  水产企业: {
    resourceId: "bc364ea2-f89c-4108-843c-426274556918",
    name: "POI_9",
  },
  厕所: {
    resourceId: "fe950406-c522-4b32-a5d9-1d61e3af8afe",
    name: "POI_10",
  },
  基本农田: {
    resourceId: "7804470a-122b-4466-ba2f-2e03ede352c2",
    name: "POI_11",
  },
  虾苗经纪人: {
    resourceId: "a4719fa5-4a64-4cc5-bc2b-0446c17a5ed1",
    name: "POI_12",
  },
  承包土地: {
    resourceId: "9bcf7b42-59ce-40ac-b083-4ddf793273f8",
    name: "POI_13",
  },
  虾销售经纪人: {
    resourceId: "83fb1446-3822-48d3-8530-d8919dcce8e7",
    name: "POI_20",
  },
  集体土地地块: {
    resourceId: "49786caf-490e-4199-b931-8184f25bc9f7",
    name: "POI_21",
  },
  垃圾站: {
    resourceId: "78f21a36-a330-4ce1-b345-feb77623d034",
    name: "POI_14",
  },
  耕地地块: {
    resourceId: "d8e98280-2dfa-4659-8cc4-3f057d8ede7a",
    name: "POI_15",
  },
  塘口: {
    resourceId: "9c7da8ff-f5bc-4b88-9f1a-f893a9b86285",
    name: "POI_17",
  },
  林地: {
    resourceId: "2d5f3e39-4c31-4b12-9590-eb2b1093703b",
    name: "POI_16",
  },
  虾苗企业: {
    resourceId: "92a8b74e-f1db-48b3-a0a9-3c85b939e5a3",
    name: "POI_18",
  },
  网格: {
    resourceId: "ea51099f-823a-4a3e-9d2e-0a1248aa5394",
    name: "POI_19",
  },
};
import {
  getOldPeo,
  getWgyPeo,
  getCjrPeo,
  getWgzPeo,
  getDyPeo,
  getYfdxPeo,
  getJzymPeo,
} from "../api/bigscreen.js";
export let poiList = [];
// eslint-disable-next-line no-unused-vars
let oldPeo = [],
  wgyPeo = [],
  cjrPeo = [],
  wgzPeo = [],
  dyPeo = [],
  jzymPeo = [],
  yfdxPeo = [];
export const poiListByType = {
  宅基地: [],
  党员: [],
  老年人: [],
  微网格员: [],
  残疾人: [],
  网格长: [],
  优抚对象: [],
  // 接种疫苗: [],
};
export const refreshPoiList = async () => {
  if (poiList && poiList.length > 0) {
    oldPeo = await getOldPeo();
    wgyPeo = await getWgyPeo();
    cjrPeo = await getCjrPeo();
    wgzPeo = await getWgzPeo();
    dyPeo = await getDyPeo();
    yfdxPeo = await getYfdxPeo();
    // jzymPeo = await getJzymPeo();
    //对宅基地分类
    // debugger;
    for (let res of poiList) {
      // is_aged,is_member,is_manage,is_disability
      if (dyPeo.some((x) => x.huzhu == res.textContent)) {
        poiListByType["党员"].push(res);
      }
      if (oldPeo.some((x) => x.huzhu == res.textContent)) {
        poiListByType["老年人"].push(res);
      }
      if (wgyPeo.some((x) => x.huzhu == res.textContent)) {
        poiListByType["微网格员"].push(res);
      }
      if (cjrPeo.some((x) => x.huzhu == res.textContent)) {
        poiListByType["残疾人"].push(res);
      }
      if (wgzPeo.some((x) => x.huzhu == res.textContent)) {
        poiListByType["网格长"].push(res);
      }
      if (yfdxPeo.some((x) => x.huzhu == res.textContent)) {
        poiListByType["优抚对象"].push(res);
      }
      // if (jzymPeo.some((x) => x.huzhu == res.textContent)) {
      //   poiListByType["接种疫苗"].push(res);
      // }
      console.log("dealdata");
    }
    //按照宅基地的分类进行新增
    let y = 0;
    debugger;
    for (let name in poiListByType) {
      let a = [];
      let poilistByName = await getPoiList({ tags: [name] });
      if (poilistByName && poilistByName.length > 0) {
        continue;
      }
      for (let res of poiListByType[name]) {
        let onePoi = await addPoi({
          flyToModel: false,
          resourceId: llll[name].resourceId,
          tags: [name],
          position: res.position,
          textContent: res.textContent,
          name: res.textContent,
        });
        a.push(onePoi);
        // eslint-disable-next-line no-const-assign
        console.log(++y);
      }
      await displayObject({ display: false, ids: a.map((ele) => ele.id) });
      poiListByType[name] = a;
    }
    poiListByType["宅基地"] = poiList;
  }
};
// const loadDataPannel = async () => {
//   Dandelion.RtAPI("getDataPannelList", { view: true }, (data) => {
//     console.log(data);
//     // for(let res of data){
//     Dandelion.RtAPI(
//       "setDisplayDataPannel",
//       { display: false, ids: data.map((res) => res.id) },
//       (res2) => {
//         console.log(res2);
//       }
//     );
//   });
// };
import { arrayUtil } from "../uitl";
const { isIntersection } = arrayUtil;

export const load = async () => {
  //   const allPoiList = await getPoiList();
  //   for (let element of allPoiList) {
  //     if (
  //       element.tags.includes("党员") ||
  //       element.tags.includes("老年人") ||
  //       element.tags.includes("网格员") ||
  //       element.tags.includes("残疾人") ||
  //       element.tags.includes("网格长") ||
  //       element.tags.includes("优抚对象")
  //     ) {
  //       console.log(123);
  //       await deletePoi({ id: element.id });
  //     }
  //   }
  //   poiList = await getPoiList({ tags: ["宅基地"] });
  //   await refreshPoiList();

  //整理河道监控poi
  const hedaoVideo = (await getObjectByTag(["河道y"])).Poi || [];
  //整理单点监控poi
  const ddVideo = (await getObjectByTag(["监控y"])).Poi || [];
  //整理随手拍poi
  // const sspVideo = (await getObjectByTag(["随手拍"])).Poi;
  // for (let res of allPoiList) {
  //   if (isIntersection(res.tags, ["河道"])) {
  //     hedaoVideo.push(res);
  //   }
  //   if (isIntersection(res.tags, ["监控"])) {
  //     ddVideo.push(res);
  //   }
  //   if (isIntersection(res.tags, ["随手拍"])) {
  //     sspVideo.push(res);
  //   }
  // }
  // window.localStorage.setItem("hedaoVideo", JSON.stringify(hedaoVideo));
  // window.localStorage.setItem("ddVideo", JSON.stringify(ddVideo));
  // window.localStorage.setItem("sspVideo", JSON.stringify(sspVideo));
  // window.localStorage.setItem("allPoiList", JSON.stringify(allPoiList));
  // getObjectByTag(["河道"]).then(res=>{
  //   console.log(res)
  // })
  //播放
  // const videoList = await getVideoList({ view: false });
  // debugger;
  displayObject({
    display: true,
    ids: hedaoVideo.map((res) => res.bindID),
    conditions: 0,
  });
  displayObject({
    display: true,
    ids: ddVideo.map((res) => res.bindID),
    conditions: 0,
  });
  for (let res of hedaoVideo) {
    playVideo({ id: res.bindID, videoState: 0 });
  }
  for (let res of ddVideo) {
    playVideo({ id: res.bindID, videoState: 0 });
  }
};
export const videoPoiAndCenterPoint = {
  //西面塔向南球机 39a2b220cf-69eb-d66e-5aeb-901f56e04c  119.555136194,32.9204483578,16.3082109457
  // http://223.108.178.130:880/cosmos/761433904065552384.flv
  "39a2b220cf-69eb-d66e-5aeb-901f56e04c": {
    height: 65,
    // height: 70.96488701738417,
    id: "39a2b220cf-69eb-d66e-5aeb-901f56e04c",
    latitude: 32.91935079884077,
    longitude: 119.5526204908769,
    streamId: "761433904065552384",
    aroundRangeDis: 3000,
    pangleRange: 360,
    tangleRange: 90,
    pfactor: 1,
    tfactor: 1,
  },
  // http://223.108.178.130:880/cosmos/761429877546295296.flv西面塔向北
  // 67b99fb728-cd80-043b-6e4d-a9c2603b2e 西面塔向北摄像机
  "67b99fb728-cd80-043b-6e4d-a9c2603b2e": {
    height: 65,
    // height: 70.96488701738417,
    id: "67b99fb728-cd80-043b-6e4d-a9c2603b2e",
    latitude: 32.91924200373989,
    longitude: 119.56394395684082,
    streamId: "761429877546295296",
    aroundRangeDis: 3000,
    pangleRange: 360,
    tangleRange: 90,
    pfactor: 1,
    tfactor: 1,
  },
  // http://223.108.178.130:880/cosmos/757846920717934592.flv
  //东面塔向南球机  a16c4cbb11-06ac-e4cf-ae95-495105bccd
  "a16c4cbb11-06ac-e4cf-ae95-495105bccd": {
    height: 45,
    // height: 70.96488701738417,
    id: "a16c4cbb11-06ac-e4cf-ae95-495105bccd",
    latitude: 32.921543599154454,
    longitude: 119.58167955089316,
    streamId: "757846920717934592",
    aroundRangeDis: 3000,
    pangleRange: 360,
    tangleRange: 90,
    pfactor: 1,
    tfactor: 1,
  },
  // http://223.108.178.130:880/cosmos/757846920717934592.flv
  //东面塔向北球机  a5e59535cf-2b6f-d84b-c8b1-f805df4131
  "a5e59535cf-2b6f-d84b-c8b1-f805df4131": {
    height: 45,
    // height: 70.96488701738417,
    id: "a5e59535cf-2b6f-d84b-c8b1-f805df4131",
    latitude: 32.919626316369865,
    longitude: 119.58214683896496,
    streamId: "757849185692426240",
    aroundRangeDis: 3000,
    pangleRange: 360,
    tangleRange: 90,
    pfactor: 1,
    tfactor: 1,
  },
};
//西面塔向南 004ecdd08f-2ae9-4ecf-74f0-9fc882b4fc  119.555136194,32.9204483578,16.3082109457
//西面塔向北 7d74c754a0-ffcf-367-465b-5fa28360b9   119.556091512,32.9204700682,16.611487465
//东面塔像南 a16c4cbb11-06ac-e4cf-ae95-495105bccd  119.582417042,32.920538436,1.48652710766
//东面塔像北 a5e59535cf-2b6f-d84b-c8b1-f805df4131  119.582367344,32.9197801389,0.79182282649
//绑定
videoTrack({
  para: Object.values(videoPoiAndCenterPoint).map((res) => {
    delete res.streamId;
    return res;
  }),
});
