<template>
  <div class="coverbox">
    <div class="container">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>
<script lang="js">
import {  defineComponent   } from "vue";

export default defineComponent({
  name: "OverBox",
  components:{},
  setup() {
      return {};
    },
});
</script>
<style>
.coverbox {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  /* box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.6); */
  /* background: rgba(0, 0, 0, 0.1); */
  background: #dbdbdb;
}
.container {
  position: relative;
  width: 200px;
  height: 200px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -60%);
}

.container > div {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -10px;
  width: 50%;
  height: 20px;
  transform-origin: left center;
}

.container > div::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  /* background-color: red; */
  background-color: #000;
  /* background-color: blue; */
  /* transform: var(--scale); */
  animation: dotscale 1.2s linear infinite;
  animation-delay: var(--animation-delay);
}

.container > div:nth-child(1) {
  transform: rotate(0deg);
  --animation-delay: 0s;
}

.container > div:nth-child(2) {
  transform: rotate(30deg);
  --animation-delay: -0.1s;
}

.container > div:nth-child(3) {
  transform: rotate(60deg);
  --animation-delay: -0.2s;
}

.container > div:nth-child(4) {
  transform: rotate(90deg);
  --animation-delay: -0.3s;
}

.container > div:nth-child(5) {
  transform: rotate(120deg);
  --animation-delay: -0.4s;
}

.container > div:nth-child(6) {
  transform: rotate(150deg);
  --animation-delay: -0.5s;
}

.container > div:nth-child(7) {
  transform: rotate(180deg);
  --animation-delay: -0.6s;
}

.container > div:nth-child(8) {
  transform: rotate(210deg);
  --animation-delay: -0.7s;
}

.container > div:nth-child(9) {
  transform: rotate(240deg);
  --animation-delay: -0.8s;
}

.container > div:nth-child(10) {
  transform: rotate(270deg);
  --animation-delay: -0.9s;
}

.container > div:nth-child(11) {
  transform: rotate(300deg);
  --animation-delay: -1s;
}

.container > div:nth-child(12) {
  transform: rotate(330deg);
  --animation-delay: -1.1s;
}

@keyframes dotscale {
  0% {
    transform: scale(1);
    filter: hue-rotate(0deg);
  }

  100% {
    transform: scale(0);
    filter: hue-rotate(360deg);
  }
}
</style>
