import axios from "axios"; // 引入axios
// import QS from "qs"; // 引入qs模块，用来序列化post类型的数据

// axios.defaults.baseURL = "http://192.168.2.236:8889";
// http://192.168.2.236:8889/v1/api/ptz/continuousmove/60271924946299289637?action=1&command=ZOOM&speed=20
/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
function get(url, params) {
  // url = "http://192.168.2.236:8889" + url;
  // url = "http://192.168.0.103:8889" + url;
  url = "https://gaoyou5.xingliancun.cn" + url;
  return new Promise((resolve, reject) => {
    axios
      .get(url, { params: params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
// function post(url, params) {
//   // url = "http://192.168.2.236:8889" + url;
//   // url = "http://192.168.0.103:8889/" + url;
//   return new Promise((resolve, reject) => {
//     var parameters = QS.stringify(params);
//     // var parameters =params
//     axios
//       .post(url, parameters)
//       .then((res) => {
//         resolve(res.data);
//       })
//       .catch((err) => {
//         reject(err.data);
//       });
//   });
// }
function changePtz(params) {
  return get("/v1/api/ptz/absolutemove/" + params.uid, params);
}

export default {
  changePtz,
};
