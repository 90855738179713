<!-- eslint-disable no-unused-vars -->
<template>
  <div class="scene" id="player" :style="mapStyle">
    <video
      id="webrtc_video"
      x-webkit-airplay="true"
      playsinline="true"
      webkit-playsinline="true"
      x5-video-player-type="h5"
      x5-video-player-fullscreen="true"
      x5-video-orientation="portraint"
      loop="true"
      controlslist="nodownload nofullscreen noremoteplayback"
    ></video>
  </div>
  <Pop
    :popStyle="popStyle"
    :popShow="popShow"
    :peo="peo"
    @offPop="offPOP"
    :isTk="isTk"
  ></Pop>

  <zoomBar
    v-if="ismobile"
    @enlarge="enlargeDo"
    @narrow="narrowDo"
    @home="home"
  ></zoomBar>
  <checkbox @changeBox="changeBox" v-if="true" />
  <div @touchstart="touchHome" v-show="ispaly" class="play"></div>
  <OverBox v-show="isloaded" />
  <eventHtml />
  <popEchars
    :isSzjcDisplay="isSzjcDisplay"
    :poiDetail="poiDetail"
    @offEeacharBox="OffEeacharBox"
  />
</template>
<!-- <div class="home" @click="toHome()"></div> -->
<script lang="js">
import { onMounted, defineComponent, ref, reactive} from "vue";
import { isMobile,isIOS } from 'react-device-detect';
import { useRouter } from 'vue-router'
let routerText=''
// import _axios from "../api/axios.js";
import Pop from "../components/pop";
import zoomBar from "../components/zoomBar";
import eventHtml from "../components/eventHtml";
import popEchars from "../components/popEchars";
import checkbox from "../components/checkbox";
import OverBox from "./overbox/OverBox";
// eslint-disable-next-line no-unused-vars
import { load,poiListByType,videoPoiAndCenterPoint } from "./load";
// eslint-disable-next-line no-unused-vars
import { ss } from "./test";
// eslint-disable-next-line no-unused-vars
import { arrayUtil } from "../uitl";
// import { useRouter } from 'vue-router'
import {  sendMessage   } from "@/eventProcessing";
// eslint-disable-next-line no-unused-vars
import * as PassApi from "../api/passApi.js";
import  Dsp from "../api/dsp.js";
// eslint-disable-next-line no-unused-vars
import { GetALLCountryGridByGridname,getCountryGarbagedisposalinfosByName,getCountryDomesticsewageinfosByName,getCountryToiletimprovementinfosByName,getCountryRefuseclassificationinfosByName,getCountryHomesteadinfosByName,getCountryContractedlandinfosByName,getCountryCollectivelandinfosByName,getCountryCultivatedlandinfosByName,getCountryBasicfarmlandinfosByName,getCountryGardeninformationinfosByName,getCountryPopulationByName ,
  // eslint-disable-next-line no-unused-vars
  getShrimpSaleAgentByName,getShrimpAgentByName,getShrimpEnterprisesByName,getShrimpOemByName,getShrimpPondCultureByName,selectCoutrySnapshotListByState,
  getOldPeo,getWgyPeo,getCjrPeo,getWgzPeo,getDyPeo,getYfdxPeo,getJzymPeo,getDzz,getStzyfqy,getXypflqy,getSlqy,getXx,getRwly,getSspByName,getPeoByHuzhu} from "../api/bigscreen.js";

const tagToApi = {
      "垃圾站":getCountryGarbagedisposalinfosByName,//country_garbagedisposalinfos
      "承包土地":getCountryContractedlandinfosByName,//country_contractedlandinfos
      // "垃圾分类":getCountryRefuseclassificationinfosByName,//country_refuseclassificationinfos
      "厕所":getCountryToiletimprovementinfosByName,//country_toiletimprovementinfos
      "基本农田":getCountryBasicfarmlandinfosByName,//country_basicfarmlandinfos
      // "宅基地":getCountryHomesteadinfosByName,//country_homesteadinfos
      "耕地地块":getCountryCultivatedlandinfosByName,//country_cultivatedlandinfos
      "虾苗企业":getShrimpEnterprisesByName,//shrimp_enterprises
      "集体土地地块":getCountryCollectivelandinfosByName,//country_collectivelandinfos
      "成虾销售经纪人":getShrimpSaleAgentByName,//shrimp_sale_agent
      "林地":getCountryGardeninformationinfosByName,//country_gardeninformationinfos
      "塘口":getShrimpPondCultureByName,//shrimp_pond_culture
      "高位池":getShrimpPondCultureByName,//shrimp_pond_culture
      "水产企业":getShrimpOemByName,//shrimp_oem,
      "污水处理":getCountryDomesticsewageinfosByName,//country_domesticsewageinfos
      "虾苗经纪人":getShrimpAgentByName,//shrimp_agent
      "网格":GetALLCountryGridByGridname,//country_grid
      "宅基地":getCountryPopulationByName,//
      "党员":getCountryPopulationByName,
      "老年人":getCountryPopulationByName,
      "网格员":getCountryPopulationByName,
      "网格长":getCountryPopulationByName,
      "残疾人":getCountryPopulationByName,
      "优抚对象":getCountryPopulationByName,
      "接种疫苗":getCountryPopulationByName,
      "党组织":getDzz,//country_party
      "生态专用肥生产企业":getStzyfqy,//shrimp_fertilizer_enterprises 29
      "虾药品肥生产企业":getXypflqy,//shrimp_drug_manufacturer
      "饲料企业":getSlqy,//shrimp_feed_enterprises
      "学校":getXx,//country_schoolstatistics
      "人文旅游":getRwly,//humanistic_tourism
      "随手拍":getSspByName,
      "河道":null,
      "监控":null,
      "水质检测":null,
 }
const tagToJson = {
      "垃圾站":{"垃圾处理名称":'name',"责任人姓名":'zrrname',"责任人联系电话":'zrrtels'},//country_garbagedisposalinfos
      "承包土地":{"承包方姓名":'name',"地块名称":'parcelname',"地类":'landtype',"涉及地块数":{key:'counts',value:async (name)=>{ return name&&name!=''?name+' 块':'未知' }}},//country_contractedlandinfos
      // "垃圾分类":{"垃圾分类名称":'name',"负责人":'username',"联系电话":'tels','可回收物产生量（吨）':'recyclablecounts','有害垃圾产生量（吨）':'harmfulwastecounts','厨余垃圾产生量（吨）':'kitchenwastecounts','其它垃圾产生量（吨）':'othergarbagecounts'},//country_refuseclassificationinfos
      "厕所":{"农村卫生改厕名称":'name',"改厕建成时间":'completiontime','卫生厕所面积':{key:'toiletarea',value:async (name)=>{ return name&&name!=''?name+' 平方米':'未知' }},'卫生厕所数量':{key:'numberseats',value:async (name)=>{ return name&&name!=''?name+' 座':'未知' }}},//country_toiletimprovementinfos
      "基本农田":{"基本农田名称":'name','水田面积':{key:'cultivatedarea',value:async (name)=>{ return name&&name!=''?name+' 公顷':'未知' }},'水浇地面积':{key:'lrrigatelandarea',value:async (name)=>{ return name&&name!=''?name+' 公顷':'未知' }},'旱地面积':{key:'drylandarea',value:async (name)=>{ return name&&name!=''?name+' 公顷':'未知' }}},//country_basicfarmlandinfos
      // "宅基地":{"自然村名称":'cunname',"户主名称":'name',"宅基地地址":'address',"宅基地面积（平方米）":'homesteadarea'},//country_homesteadinfos
      "耕地地块":{"耕地名称":'name',"耕地面积(公顷)":'cultivatedarea',"地形地貌":'topographicfeatures',"灌溉方式":'irrigationmethod',"创建时间":'createTime'},//country_cultivatedlandinfos
      "虾苗企业":{"企业名称":'name',"地址":'address',"育苗水体":'nursery',"联系人":'natureName',"联系电话":'phone'},//shrimp_enterprises
      "集体土地地块":{"集体土地名称":'ownename',"集体土地归属":'ownership',"联系人姓名":'name',"联系电话":'tels',"土地面积（公顷）":'acreage'},//country_collectivelandinfos
      "成虾销售经纪人":{"联系人":'nutureName',"电话":'phone',"地址":'address'},//shrimp_sale_agent
      "林地":{"林地名称":'name',"林地详细地址":'address',"流转性质":'naturecirculation',"经营权":'managementright'},//country_gardeninformationinfos
      "塘口":{"塘主姓名":'name',"亩数":{key:'areaValue',value:async (name)=>{ return name&&name!=''?name+' 亩':'未知' }},"价格":{key:'prise',value:async (name)=>{ return name&&name!=''?name+' 万元/年':'未知' }},"承包年限":{key:'year',value:async (name)=>{ return name&&name!=''?name+' 年':'未知' }},"承包开始时间":'startTime',"承包结束时间":'endTime',"虾苗尾数":{key:'yield',value:async (name)=>{ return name&&name!=''?name+' 万尾/亩':'未知' }},"饲料品牌":"brand"},//shrimp_pond_culture
      "高位池":{"塘主姓名":'name',"亩数":{key:'areaValue',value:async (name)=>{ return name&&name!=''?name+' 亩':'未知' }},"价格":{key:'prise',value:async (name)=>{ return name&&name!=''?name+' 万元/年':'未知' }},"承包年限":{key:'year',value:async (name)=>{ return name&&name!=''?name+' 年':'未知' }},"承包开始时间":'startTime',"承包结束时间":'endTime',"虾苗尾数":{key:'yield',value:async (name)=>{ return name&&name!=''?name+' 万尾/亩':'未知' }},"饲料品牌":"brand"},//shrimp_pond_culture
      "涉农企业":{"企业名称":'name',"地址":'address',"品牌":'brand',"年产值":"yearValue"},//shrimp_oem,
      "污水处理":{"生活污水处理名称":'name',"责任人姓名":'zrrname',"责任人联系电话":'zrrtels',"污水排放标准":'emissionstandard',"污水管网情况":'sewagepipe'},//country_domesticsewageinfos
      "虾苗经纪人":{"联系人":'natureName',"联系电话":'phone',"地址":'address'},//shrimp_agent
      "网格":{"网格名称":{key:'name',value:async (name)=>{ return name }},"网格长姓名":'manageName',"网格员姓名":'memberName',"网格人员数量":'peoCount'},//country_grid
      "宅基地":{"户主姓名":"huzhu","宅基地地址":'address',"宅基地面积":{key:'homesteadarea',value:async (name)=>{ return name&&name!=''?name+' 平方米':'未知' }},"户籍类型":{key:'koseki',value:[null,'城镇户籍','农业户籍']},"所属村小组":{key:'groupId',value:{"11":"一组","12":"二组","13":"三组","14":"四组","15":"五组","16":"六组","17":"七组","18":"八组","19":"九组","20":"十组","21":"十一组","22":"十二组","23":"十三组"}},"姓名":{key:'name',value:async (name)=>{ return name }},"该户党员":{key:'huzhu',value:async (name)=>{ const data=await getDyPeo(name); let str='';for(let res of data){str=str+res.name+','+res.age+'岁;' } return str?str:'无'},"该户人口数": {key:'name',value:async (name)=>{ const data=await getPeoByHuzhu(name); return data.length }}}},//country_population
      "老年人":{"户主姓名":"huzhu","宅基地地址":'address',"宅基地面积":{key:'homesteadarea',value:async (name)=>{ return name&&name!=''?name+' 平方米':'未知' }},"户籍类型":{key:'koseki',value:[null,'城镇户籍','农业户籍']},"所属村小组":{key:'groupId',value:{"11":"一组","12":"二组","13":"三组","14":"四组","15":"五组","16":"六组","17":"七组","18":"八组","19":"九组","20":"十组","21":"十一组","22":"十二组","23":"十三组"}},"老年人":{key:'huzhu',value:async (name)=>{const data=await getOldPeo(name); let str=''; for(let res of data){str=str+res.name+','+res.age+'岁;'} return str }}},//country_population
      "党员":{"户主姓名":"huzhu","宅基地地址":'address',"宅基地面积":{key:'homesteadarea',value:async (name)=>{ return name&&name!=''?name+' 平方米':'未知' }},"户籍类型":{key:'koseki',value:[null,'城镇户籍','农业户籍']},"所属村小组":{key:'groupId',value:{"11":"一组","12":"二组","13":"三组","14":"四组","15":"五组","16":"六组","17":"七组","18":"八组","19":"九组","20":"十组","21":"十一组","22":"十二组","23":"十三组"}},"党员":{key:'huzhu',value:async (name)=>{const data=await getDyPeo(name); let str='';for(let res of data){str=str+res.name+','+res.age+'岁;'}     return str}}},//country_population
      "网格员":{"户主姓名":"huzhu","宅基地地址":'address',"宅基地面积":{key:'homesteadarea',value:async (name)=>{ return name&&name!=''?name+' 平方米':'未知' }},"户籍类型":{key:'koseki',value:[null,'城镇户籍','农业户籍']},"所属村小组":{key:'groupId',value:{"11":"一组","12":"二组","13":"三组","14":"四组","15":"五组","16":"六组","17":"七组","18":"八组","19":"九组","20":"十组","21":"十一组","22":"十二组","23":"十三组"}},"网格员":{key:'huzhu',value:async (name)=>{const data=await getWgyPeo(name); let str='';for(let res of data){str=str+res.name+','+res.age+'岁;'}  return str}}},//country_population
      "网格长":{"户主姓名":"huzhu","宅基地地址":'address',"宅基地面积":{key:'homesteadarea',value:async (name)=>{ return name&&name!=''?name+' 平方米':'未知' }},"户籍类型":{key:'koseki',value:[null,'城镇户籍','农业户籍']},"所属村小组":{key:'groupId',value:{"11":"一组","12":"二组","13":"三组","14":"四组","15":"五组","16":"六组","17":"七组","18":"八组","19":"九组","20":"十组","21":"十一组","22":"十二组","23":"十三组"}},"网格长":{key:'huzhu',value:async (name)=>{const data=await getWgzPeo(name); let str='';for(let res of data){str=str+res.name+','+res.age+'岁;'}  return str}}},//country_population
      "残疾人":{"户主姓名":"huzhu","宅基地地址":'address',"宅基地面积":{key:'homesteadarea',value:async (name)=>{ return name&&name!=''?name+' 平方米':'未知' }},"户籍类型":{key:'koseki',value:[null,'城镇户籍','农业户籍']},"所属村小组":{key:'groupId',value:{"11":"一组","12":"二组","13":"三组","14":"四组","15":"五组","16":"六组","17":"七组","18":"八组","19":"九组","20":"十组","21":"十一组","22":"十二组","23":"十三组"}},"残疾人":{key:'huzhu',value:async (name)=>{const data=await getCjrPeo(name); let str='';for(let res of data){str=str+res.name+','+res.age+'岁;'}  return str}}},//country_population
      "优抚对象":{"户主姓名":"huzhu","宅基地地址":'address',"宅基地面积":{key:'homesteadarea',value:async (name)=>{ return name&&name!=''?name+' 平方米':'未知' }},"户籍类型":{key:'koseki',value:[null,'城镇户籍','农业户籍']},"所属村小组":{key:'groupId',value:{"11":"一组","12":"二组","13":"三组","14":"四组","15":"五组","16":"六组","17":"七组","18":"八组","19":"九组","20":"十组","21":"十一组","22":"十二组","23":"十三组"}},"退、现役军人":{key:'huzhu',value:async (name)=>{const data=await getYfdxPeo(name); let str='';for(let res of data){str=str+res.name+','+res.age+'岁;'} return str }}},//country_population
      "接种疫苗":{"户主姓名":"huzhu","宅基地地址":'address',"宅基地面积":{key:'homesteadarea',value:async (name)=>{ return name&&name!=''?name+' 平方米':'未知' }},"户籍类型":{key:'koseki',value:[null,'城镇户籍','农业户籍']},"所属村小组":{key:'groupId',value:{"11":"一组","12":"二组","13":"三组","14":"四组","15":"五组","16":"六组","17":"七组","18":"八组","19":"九组","20":"十组","21":"十一组","22":"十二组","23":"十三组"}},"接种疫苗":{key:'huzhu',value:async (name)=>{const data=await getJzymPeo(name); let str='';for(let res of data){str=str+res.name+','+res.age+'岁;'} return str }}},//country_population
      "党组织":{"党组织名称":"oname","党组织书记":"secretary","党组织联系人":"contacts","通讯地址":"address","联系电话":"telephone"},//country_party
      "生态专用肥生产企业":{"企业名称":"name","地址":"address","联系人":"natureName","联系电话":"phone"},//shrimp_fertilizer_enterprises
      "虾药品肥生产企业":{"企业名称":"name","地址":"address","品牌":"brand","联系人":"natureName","联系电话":"phone"},//shrimp_drug_manufacturer
      "饲料企业":{"企业名称":"name","地址":"address","品牌":"brand","联系人":"naturName","联系电话":"phone"},//shrimp_feed_enterprises
      "学校":{"学校名称":"name","学校地址":"address","占地面积":"schoolarea","联系电话":"tel","学校介绍":"introduce"},//country_schoolstatistics
      "人文旅游":{"标题":"title"},//humanistic_tourism
}
const isTk=ref(false);
const popAbout=()=>{
    const popShow=ref(false);
    const popStyle=reactive({top:0,left:0});
    // name:'哈哈哈',"relation":'户主',"address":'江苏省扬州市高邮市三垛镇,兴联村111号'
    const peo=reactive({});

    const loadPopContext=async (One,tag) => {
          // name,title,tags
          popShow.value=false;
          const one=Array.isArray(One)?One[0]:One;
          if(!one||JSON.stringify(one)=='{}') return;
          setTimeout(() => {
            popShow.value=true;
          }, 10);
          try {
                const nameValue=tagToJson[tag];
                Object.keys(peo).forEach(ele => delete peo[ele] );
                for(let name in  nameValue){
                  let getValueKey=nameValue[name];
                  if(typeof getValueKey==='string'){
                      peo[name]=one[getValueKey];
                  } else if(Array.isArray(getValueKey.value)||typeof getValueKey.value=='object') {
                    let key=getValueKey.key
                    peo[name]=getValueKey.value[one[key]];
                  }else if(typeof getValueKey.value == 'function'){
                    let key=getValueKey.key
                    peo[name]=await getValueKey.value(one[key]);
                  }
                }
                peo.tag=tag
                if(["宅基地","党员","老年人","网格员","残疾人","网格长",'优抚对象','接种疫苗'].includes(tag)){
                      peo.tag='宅基地'
                }
                if(peo.tag==='塘口'&&(routerText==='第一产业'||routerText==='第二产业'||routerText==='第三产业')){
                  isTk.value=true
                }else {
                  isTk.value=false
                }
          }catch(error){
              setTimeout(() => {
                popShow.value=false;
              }, 6000);
              throw new Error(error)
          }finally{
              setTimeout(() => {
                popShow.value=false;
              }, 6000);
          }
    }

    const movePop=(x,y)=>{
      // x,y
      // popStyle.left=x*window.innerWidth+'px';
      // popStyle.top=y*window.innerHeight+'px';
      popStyle.left=x*document.getElementById('player').offsetWidth+'px'
      popStyle.top=y*document.getElementById('player').offsetHeight+'px';
      // popStyle.left='50%';
      // popStyle.top='50%';
    }
    const offPOP=()=>{
      popShow.value=false
    }
    // getViewPoint().then(res=>console.log(JSON.stringify(res)))
    return { popShow, popStyle, peo, loadPopContext, movePop,offPOP }
}
export default defineComponent({
  name: "Home",
  components:{Pop,zoomBar,checkbox,eventHtml,OverBox , popEchars},
  setup () {
    const router = useRouter();
    const { popShow,popStyle,peo,loadPopContext,movePop,offPOP }=popAbout()
    const mapStyle=reactive({})
    const ismobile=ref(isMobile);
    const isSzjcDisplay=ref(false);
    // isIOS
    const ispaly=ref(isIOS);
    const isloaded=ref(true);
    const poiDetail=ref(null);
    onMounted(async () => {

      // const router = useRouter();
      // const index=router.currentRoute.value.query.index;
      // 初始化云渲染实例
       // 在页面上显示场景
      await load();
      PassApi.joinRoom('player')
      isloaded.value=false;

      routerText=router.currentRoute.value.query.text
      // await ss()
      //上一次点击时间
      let beforClickTime=null;
      // eslint-disable-next-line no-unused-vars
      let isDoubClickFloor=false
      const  videoPoiAndCenterPoint3 = {
  // http://223.108.178.130:880/cosmos/761433904065552384.flv
  "39a2b220cf-69eb-d66e-5aeb-901f56e04c": {
    height: 45,
    // height: 70.96488701738417,
    id: "39a2b220cf-69eb-d66e-5aeb-901f56e04c",
    latitude: 32.9204483578,
    longitude: 119.555136194,
    streamId: "761433904065552384",
  },
  // http://223.108.178.130:880/cosmos/761429877546295296.flv西面塔向北
  "67b99fb728-cd80-043b-6e4d-a9c2603b2e": {
    height: 45,
    // height: 70.96488701738417,
    id: "67b99fb728-cd80-043b-6e4d-a9c2603b2e",
    latitude: 32.92051442646431,
    longitude: 119.5558899630735,
    streamId: "761429877546295296",
  },
  // http://223.108.178.130:880/cosmos/757846920717934592.flv
  "a16c4cbb11-06ac-e4cf-ae95-495105bccd": {
    height: 35,
    // height: 70.96488701738417,
    id: "a16c4cbb11-06ac-e4cf-ae95-495105bccd",
    latitude: 32.920538436,
    longitude: 119.582417042,
    streamId: "757846920717934592",
  },
  // http://223.108.178.130:880/cosmos/757846920717934592.flv
  "a5e59535cf-2b6f-d84b-c8b1-f805df4131": {
    height: 35,
    // height: 70.96488701738417,
    id: "a5e59535cf-2b6f-d84b-c8b1-f805df4131",
    latitude: 32.9197801389,
    longitude: 119.582367344,
    streamId: "757849185692426240",
  },
      }
      //给poi添加点击事件
      PassApi.registerClick({"poi":true,"area":true,"coordinate":true},async (res)=>{
          console.log(res)
          if(res&&res.PICKELEM_POI&&res.PICKELEM_POI.id) {
            //获取pio详情
            const poiOne=await PassApi.getPoiDetail(res.PICKELEM_POI.id);
            //点击后有操作的tag
            const tagPop=Object.keys(tagToApi)
            let tag = ''
            for(let ta of poiOne['tags']){
                if(tagPop.includes(ta)){
                  tag=ta;break;
                }
            }
            switch(true) {
              case tag=='河道'||tag=='监控': {
                  //飞过去
                  // PassApi.locationSceneObject({objectId: poiOne.bindID})
                  PassApi.locationSceneObject({objectId: poiOne.id})
                  // sendMessage('clickHedaoPoi',poiOne)
              }break;
              case tag=='随手拍':{
                let searchText=poiOne.textContent;
                const one = await tagToApi[tag]({name:searchText});
                sendMessage('clickSspPoi',one)
              }break;
              case tag=='水质检测':{
                // sendMessage('clickhhhhPoi',poiOne)
                isSzjcDisplay.value=true
                poiDetail.value=JSON.stringify(poiOne)
              }break;
              case tag=='':return;
              default : {
                let searchText=poiOne.textContent;
                if(tag=='塘口'){
                    searchText=searchText.substring(0,searchText.indexOf('渔塘'))
                }
                if(tagToApi[tag]){
                    //获取后台数据
                    const one = await tagToApi[tag]({name:searchText});
                    //加载村名信息，显示弹框
                    await loadPopContext(one,tag);
                    //移动弹框
                    movePop(res.PICKELEM_POI.proportionX,res.PICKELEM_POI.proportionY)
                }
              }
            }
          }
          //判断是否连续两次点击地板
          if(res&&res.PICKPOINT){
              if(beforClickTime&&(new Date().getTime()-beforClickTime)<300){
                  isDoubClickFloor=true;
              }else{
                  isDoubClickFloor=false;
                  beforClickTime=new Date().getTime()
              }
          }
          if(res&&res.VIDEO_TRACK&&JSON.parse(window.localStorage.getItem("displayGroup"))["球机追视"]&&isDoubClickFloor) {
              sendMessage('openVideo',res)
              if(res.VIDEO_TRACK.pan=='0'&&res.VIDEO_TRACK.tilt=='0'&&res.VIDEO_TRACK.zoom=='0'){
                  return
              }
              let zoom=Number(res.VIDEO_TRACK.zoom);
              let m=zoom;
              zoom=m<=100?0.01: m>100&&m<=150?0.05: m>150&&m<400?0.1: 0.15;
              let pan=Number(res.VIDEO_TRACK.pan);
              pan=m>400?pan+0.05:pan;
              let tilt=Number(res.VIDEO_TRACK.tilt);
              tilt=m>150&&m<=400?tilt-0.1 :m>400?tilt-0.15:tilt;
              const uid=videoPoiAndCenterPoint3[res.VIDEO_TRACK.videoTrackID].streamId;
              Dsp.changePtz({pan,tilt,zoom,uid})
          }
      })
      document.addEventListener('click', () => {
          // console.log(event)
          ispaly.value=false;
      })
      if(ismobile.value){
        mapStyle.width=window.innerHeight*(16/9)+'px'
      }
      await changeBox(JSON.parse(window.localStorage.getItem("displayGroup")))
    })
      console.log(isMobile)
      const enlargeDo=(e)=>{
        console.log('节流放大')
        PassApi.enlarge(e)
      }
      const narrowDo=(e)=>{
        console.log('缩小')
        PassApi.narrow(e)
      }
      const home=(dd)=>{
        console.log(11111)
          PassApi.updateViewPoint(dd)
      }
      // eslint-disable-next-line no-unused-vars
      const changeBox = async (dd)=>{
            //清空视频
            let trueList=[],falseList=[];
            for(let res in dd){
              // console.log(dd[res])
              if(res=='随手拍'){
                        // const sspPois= JSON.parse(window.localStorage.getItem("sspVideo"));
                          const sspPois= await (await PassApi.getObjectByTag(["随手拍"])).Poi||[];
                          if(sspPois.length>0){
                            PassApi.deletePoi({idList:sspPois.map(res=>res.id)})
                          }
                          // for(let res of sspPois){
                          //   PassApi.deletePoi({id:res.id})
                          // }
                        // window.localStorage.setItem("sspVideo", JSON.stringify([]));
                    if(dd[res]&&res=='随手拍'){
                        let data=await selectCoutrySnapshotListByState();
                        // console.log(data)
                        // const sspPois=[]
                        for(let res of data){
                        //  const sspPoi=
                            PassApi.addPoi({
                            name:res.address,
                            flyToModel:false,
                            textContent:res.address,tags:['随手拍',res.id],
                            resourceId:'d9aeaea9-36ea-4bf0-b1b0-ad65794a2bc2',
                            position:{"height": 10,"latitude": res.latitude.replace('\r','').replace('\n',''),"longitude": res.longitude.replace('\r','').replace('\n','')},
                            pulse:res.state=='0'?true:false,
                            pulseTime:300000,
                            viewMaxRange:20000000,
                            viewMinRange:0
                          })
                          // sspPoi&&sspPoi.id&&sspPois.push(sspPoi)
                        }
                        // window.localStorage.setItem("sspVideo", JSON.stringify(sspPois));
                    }
              }else if(res=='退、现役军人'){
                if(dd[res]){
                  trueList=[...trueList,'优抚对象'];
                } else {
                  falseList=[...falseList,'优抚对象'];
                }
              }else if(res=='生态养殖区'){
                if(dd[res]){
                  trueList=[...trueList,'高位池'];
                } else {
                  falseList=[...falseList,'高位池'];
                }
              }else if(dd[res]){
                  trueList=[...trueList,res];
              }else {
                  falseList=[...falseList,res];
              }
            }
            const { subtract } = arrayUtil;
            falseList=subtract(falseList,trueList)
            falseList.length>0&&(await PassApi.displayObject({display:false,tags:falseList,conditions:0}))
            trueList.length>0&&PassApi.displayObject({display:true,tags:trueList,conditions:0})
            const shidianDefa={"home": false,"throughtime": 1,"vaild": true}
            if(!ismobile.value){
              switch(true){
                // case dd["球机追视"]:break;
                case dd['党组织']:PassApi.updateViewPoint({
                            "height": 5.94058895111084,
                            "latitude": 32.920265197753906,
                            "longitude": 119.57379913330078,
                            "heading": -2.1254709719911227,
                            "pitch": -89.89632521537547,
                            "range": 863.0184782177341,
                          ...shidianDefa
                        });break;
                case dd['排涝站']:PassApi.locationSceneObject({objectId: '8225a40c-d578-4e81-acf3-17d35abba9da'});break;
                case dd['闸门']:PassApi.locationSceneObject({objectId: '7dd0f012-0236-4143-9c7c-592a43823efe'});break;
                case dd['配电房']:PassApi.locationSceneObject({objectId: 'efa838d5-6617-4177-a0ee-dea2e3fd890a'});break;
                case dd['水利设施']:PassApi.locationSceneObject({objectId: '6b0a9c52-5760-44f3-934c-9a5dedc0ef6e'});break;
                case dd['养路站']:PassApi.locationSceneObject({objectId: 'c8265557-8d2e-4104-befa-f60a158f2e50'});break;
                case dd['垃圾站']:PassApi.locationSceneObject({objectId: '0fd7838a-e66b-4afa-80bb-92d4cea2eb85'});break;
                case dd['污水处理']:PassApi.locationSceneObject({objectId: '4a86281299-c1f6-da89-3e7-7e1ea1e91d'});break;
                case dd['公厕']:PassApi.locationSceneObject({objectId: '32568aa91d-15fd-67cd-7e70-3edc9e83da'});break;
                case dd['生态养殖区']:PassApi.updateViewPoint({
                          "height": 14.616203308105469,
                          "latitude": 32.93364334106445,
                          "longitude": 119.56999969482422,
                          "heading": 7.906549161183169,
                          "pitch": -25.703847941639495,
                          "range": 758.3844262818868,
                          ...shidianDefa
                        });break;
                case dd['水质检测']:PassApi.updateViewPoint({
                  "height": 4.776712417602539,
                  "latitude": 32.92002868652344,
                  "longitude": 119.57493591308594,
                  "heading": -1.3492652795297437,
                  "pitch": -29.34698850835563,
                  "range": 1496.034380319485,
                    ...shidianDefa
                });break;
                // case dd['监控']: PassApi.updateViewPoint({
                case dd['球机追视']: PassApi.updateViewPoint({
                        "height": 2.785555362701416,
                        "latitude": 32.923702239990234,
                        "longitude": 119.57169342041016,
                        "heading": -4.098438903108113,
                        "pitch": -84.16413101875223,
                        "range": 10943.21226026659,
                        ...shidianDefa
                      });break;
                case dd['河道']: PassApi.updateViewPoint({
                  "height": 22.24793815612793,
                  "latitude": 32.92021942138672,
                  "longitude": 119.57413482666016,
                  "heading": 172.5385710728261,
                  "pitch": -54.49330203502357,
                  "range": 122.364699781195,
                        ...shidianDefa
                        });break;
                case dd['公共场所']:PassApi.locationSceneObject({objectId: '80b16e50-5c0d-44a4-b0da-f2fc5bc1bc20'});break;
                case dd['卫生站']:PassApi.locationSceneObject({objectId: '6c036029-9c74-4bb7-a259-169def505dec'});break;
                case dd['村务机构']:PassApi.locationSceneObject({objectId: '809ed03b7f-28fa-4056-d0b6-1e9a290cb9'});break;
                case dd['兴楼粮站']:PassApi.locationSceneObject({objectId: '4f589d38-7eeb-4ac9-b0a2-83a3ae45c53c'});break;
                case dd['理发店']:PassApi.locationSceneObject({objectId: '0c5d5f1a-bd68-49d2-ac14-b6b397dedb2e'});break;
                // case dd['商店']:PassApi.locationSceneObject({objectId: '988f09c9e6-3947-9a21-7870-45c58aeaf2'});break;
                case dd['商店']:PassApi.locationSceneObject({objectId: '90f7ea3a-83a9-46a3-860e-75eb63563b72'});break;
                case dd['浴室']:PassApi.locationSceneObject({objectId: '29f7af68-1663-43fc-92b4-8491ac6004b8'});break;
                case dd['虾塘棚']:PassApi.locationSceneObject({objectId: 'e3d3f550-ebc9-49cc-a8cb-b30f1b69fc02'});break;
                case dd["虾苗企业"]||dd["生态专用肥生产企业"]||dd["虾药品肥生产企业"]||dd["饲料企业"]:PassApi.updateViewPoint({
                      "height": 7067.48974609375,
                      "latitude": 32.76335525512695,
                      "longitude": 119.7314682006836,
                      "heading": -1.3491316331725316,
                      "pitch": -29.34698850933269,
                      "range": 94223.74928472267,
                      ...shidianDefa
                    });break;
                case dd["水制厂"]:PassApi.locationSceneObject({objectId: 'c822a1c2c-65aa-b182-e870-df309ae551'});break;
                case dd["碾米厂"]:PassApi.locationSceneObject({objectId: 'bbcb2615-211b-4006-80c9-24225ac45a8f'});break;
                case dd["电商服务点"]:PassApi.locationSceneObject({objectId: '4da8d65b-85f0-426a-9b5d-cf6be8737ae6'});break;
                case dd["渔药门市部"]:PassApi.locationSceneObject({objectId: '90c084c9-4009-4520-8be2-c5c03a88f51c'});break;
                case dd["宅基地"]:PassApi.updateViewPoint({
                        "height": 5.550137519836426,
                        "latitude": 32.920623779296875,
                        "longitude": 119.57477569580078,
                        "heading": -1.7074528107603162,
                        "pitch": -85.74821989505215,
                        "range": 391.8646872872588,
                        ...shidianDefa
                      });break;
                case dd["老年人"]:PassApi.updateViewPoint({
                    "height": 4.157240390777588,
                    "latitude": 32.92097473144531,
                    "longitude": 119.5744857788086,
                    "heading": -3.7974676743621107,
                    "pitch": -89.89632521057686,
                    "range": 459.5527638236192,
                    ...shidianDefa
                  });break;
                  case dd["残疾人"]:PassApi.updateViewPoint(                  {
                  "height": 6.060197830200195,
                  "latitude": 32.92228317260742,
                  "longitude": 119.57447814941406,
                  "heading": 0.919903536750015,
                  "pitch": -68.39075304379068,
                  "range": 336.2351648242459,
                  ...shidianDefa
                });break;
                default:PassApi.updateViewPoint({
                        "height": 555.9987182617188,
                        "latitude": 32.918270111083984,
                        "longitude": 119.57447814941406,
                        "heading": -1.3491316331725316,
                        "pitch": -29.34698850933269,
                        "range": 7338.764250721368,
                        ...shidianDefa
                      });break;
              }
            } else {
              switch(true){
                case dd['塘口']:PassApi.updateViewPoint({
                          "height": 0.14092305302619934,
                          "latitude": 32.92317199707031,
                          "longitude": 119.57489013671875,
                          "heading": -1.3492663248405266,
                          "pitch": -29.34698850539349,
                          "range": 430.2478459412687,
                          ...shidianDefa
                      });break;
                case dd['虾塘棚']:PassApi.updateViewPoint({
                            "height": 18.623506546020508,
                            "latitude": 32.91832733154297,
                            "longitude": 119.55528259277344,
                            "heading": 1.4928539861170202,
                            "pitch": -33.93350835629647,
                            "range": 66.01423302942139,
                          ...shidianDefa
                      });break;
                case dd['高位池']:PassApi.updateViewPoint({
                  "height": 0.9766222238540649,
                  "latitude": 32.932884216308594,
                  "longitude": 119.57295989990234,
                  "heading": 8.221155680851732,
                  "pitch": -30.360354709965343,
                  "range": 765.1383175077425,
                    ...shidianDefa
                });break;
                case dd['水质检测']:PassApi.updateViewPoint({
                  "height": 4.482735633850098,
                  "latitude": 32.9193229675293,
                  "longitude": 119.57691192626953,
                  "heading": -1.3492008776201057,
                  "pitch": -29.346988499417577,
                  "range": 675.5812349964015,
                    ...shidianDefa
                });break;
                case dd['饲料仓库']:PassApi.updateViewPoint({
                  "height": 35.53093338012695,
                  "latitude": 32.90812683105469,
                  "longitude": 119.57659912109375,
                  "heading": 88.69972678374921,
                  "pitch": -56.8133829571292,
                  "range": 221.9241628472818,
                    ...shidianDefa
                });break;
                case dd['虾苗企业']:PassApi.updateViewPoint({
                    "height": 12164.1611328125,
                    "latitude": 32.80476379394531,
                    "longitude": 120.24066925048828,
                    "heading": -1.3491316331725396,
                    "pitch": -29.346988509332707,
                    "range": 162818.63876400076,
                    ...shidianDefa
                });break;
                case dd['生态专用肥生产企业']:PassApi.updateViewPoint({
                  "height": 8087.59814453125,
                  "latitude": 32.61432647705078,
                  "longitude": 120.04902648925781,
                  "heading": -1.3491316331725316,
                  "pitch": -29.34698850933269,
                  "range": 108545.75917600052,
                    ...shidianDefa
                });break;
                case dd['虾药品肥生产企业']:PassApi.updateViewPoint({
                  "height": 7057.99169921875,
                  "latitude": 32.73529052734375,
                  "longitude": 119.90371704101562,
                  "heading": -1.3491316331725316,
                  "pitch": -29.34698850933269,
                  "range": 94223.74928472267,
                    ...shidianDefa
                });break;
                case dd['饲料企业']:PassApi.updateViewPoint({
                        "height": 52.49946975708008,
                        "latitude": 32.919593811035156,
                        "longitude": 120.16388702392578,
                        "heading": -1.162400057981544,
                        "pitch": -43.44312386878243,
                        "range": 100761.21460494744,
                    ...shidianDefa
                });break;
                case dd['涉农企业']:PassApi.updateViewPoint({
                  "height": 2.701592206954956,
                  "latitude": 32.90739822387695,
                  "longitude": 119.57457733154297,
                  "heading": -1.3492013269418721,
                  "pitch": -29.346988532907233,
                  "range": 238.80672977235002,
                    ...shidianDefa
                });break;
                case dd['成虾销售经纪人']:PassApi.updateViewPoint({
                  "height": 18.13628578186035,
      "latitude": 32.908966064453125,
      "longitude": 119.58606719970703,
      "heading": -0.6326426128421886,
      "pitch": -20.084433627959015,
      "range": 85.05057509158414,
                    ...shidianDefa
                });break;
                case dd['虾苗经纪人']:PassApi.updateViewPoint({
                  "height": 2.096492290496826,
      "latitude": 32.914058685302734,
      "longitude": 119.58457946777344,
      "heading": -80.47124391066917,
      "pitch": -89.88908013109386,
      "range": 77.76067686674548,
                    ...shidianDefa
                });break;
                case dd['水制厂']:PassApi.updateViewPoint({
                  "height": 22.973546981811523,
      "latitude": 32.90907287597656,
      "longitude": 119.59162139892578,
      "heading": 86.19190224972381,
      "pitch": -41.97668106887624,
      "range": 133.2160629937742,
                    ...shidianDefa
                });break;
                case dd['碾米厂']:PassApi.updateViewPoint({
                    "height": 3.9701764583587646,
                    "latitude": 32.91563415527344,
                    "longitude": 119.59281158447266,
                    "heading": 16.182545219363046,
                    "pitch": -82.24063256572384,
                    "range": 47.289950993070995,
                    ...shidianDefa
                });break;
                case dd['商店']:PassApi.updateViewPoint({
                  "height": 4.622810363769531,
      "latitude": 32.91755676269531,
      "longitude": 119.59355926513672,
      "heading": 0,
      "pitch": -25.000000000000018,
      "range": 50,
                            ...shidianDefa
                      });break;
                case dd['电商服务点']: PassApi.updateViewPoint({
                  "height": 17.806013107299805,
                  "latitude": 32.922523498535156,
                  "longitude": 119.57460021972656,
                  "heading": 99.66300238746983,
                  "pitch": -32.61003284868808,
                  "range": 55.077415577796295,
                          ...shidianDefa
                      });break;
                case dd['渔药门市部']: PassApi.updateViewPoint(    {
                  "height": 16.794479370117188,
                  "latitude": 32.90869903564453,
                  "longitude": 119.57658386230469,
                  "heading": 0,
                  "pitch": -25.000000000000018,
                  "range": 50.00000000000018,
                          ...shidianDefa
                      });break;
                default: PassApi.updateViewPoint({
                      "height": 1.535598635673523,
                      "latitude": 32.92076873779297,
                      "longitude": 119.57511138916016,
                      "heading": -1.349266324840528,
                      "pitch": -29.346988505393476,
                      "range": 298.78322634810326,
                        ...shidianDefa
                      })
              }
            }
      }
      const OffEeacharBox=()=>{
              isSzjcDisplay.value = false
      }
      return { popShow,popStyle,peo,enlargeDo,narrowDo,mapStyle,home,ismobile,changeBox,ispaly,isloaded,isSzjcDisplay,offPOP,OffEeacharBox,isTk ,poiDetail};
  },
});
</script>

<style>
/* #app {
  width: 100%;
  height: 100%;
  inset: 0;
} */
/* .scene {
  width: 100%;
  height: 100%;
  inset: 0;
} */
/* #app {
  width: 100%;
  height: 100vh;
  inset: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
} */
/* .play {
  width: ;
} */
.play {
  position: fixed;
  left: 50%;
  top: 25%;
  width: 300px;
  height: 300px;
  border: none;
  outline: none;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url(../static/play.png);
  pointer-events: none;
  transform: translateX(-50%);
}
</style>
