import api from "./axios.js";
import api2 from "./axios2.js";
const prams = {
  huzhu: "",
  isAged: "",
  isCommunist: "",
  isManage: "",
  isMember: "",
  isDisability: "",
  isAssist: "",
};
// import {
//   YfdxPeo,
//   JzymPeo,
//   DzzPeo,
//   Stzyfqy,
//   Xypflqy,
//   Slqy,
//   Xx,
//   Rwly,
//   Zjd,
// } from "./data.js";

// function toUp(str) {
//   let newStr = "";
//   let arr = str.split("_"); //先用字符串分割成数组

//   arr.forEach((item, index) => {
//     if (index > 0) {
//       return (newStr += item.replace(item[0], item[0].toUpperCase()));
//     } else {
//       return (newStr += item);
//     }
//   });
//   return newStr;
// }
export const getOldPeo = async (huzhu) => {
  const pramsCopy = JSON.parse(JSON.stringify(prams));
  pramsCopy["isAged"] = 1;
  if (huzhu) {
    pramsCopy["huzhu"] = huzhu;
  }
  const data = await api.get("/api/h5/getCountryPopulationByHuzhu", {
    ...pramsCopy,
  });
  return data;
};
export const getPeoByHuzhu = async (huzhu) => {
  const pramsCopy = JSON.parse(JSON.stringify(prams));
  if (huzhu) {
    pramsCopy["huzhu"] = huzhu;
  }
  const data = await api.get("/api/h5/getCountryPopulationByHuzhu", {
    ...pramsCopy,
  });
  return data;
};

export const getWgyPeo = async (huzhu) => {
  const pramsCopy = JSON.parse(JSON.stringify(prams));
  pramsCopy["isMember"] = 1;
  if (huzhu) {
    pramsCopy["huzhu"] = huzhu;
  }
  const data = await api.get("/api/h5/getCountryPopulationByHuzhu", {
    ...pramsCopy,
  });
  return data;
};

export const getCjrPeo = async (huzhu) => {
  const pramsCopy = JSON.parse(JSON.stringify(prams));
  pramsCopy["isDisability"] = 1;
  if (huzhu) {
    pramsCopy["huzhu"] = huzhu;
  }
  const data = await api.get("/api/h5/getCountryPopulationByHuzhu", {
    ...pramsCopy,
  });
  return data;
};

export const getWgzPeo = async (huzhu) => {
  const pramsCopy = JSON.parse(JSON.stringify(prams));
  pramsCopy["isManage"] = 1;
  if (huzhu) {
    pramsCopy["huzhu"] = huzhu;
  }
  const data = await api.get("/api/h5/getCountryPopulationByHuzhu", {
    ...pramsCopy,
  });
  return data;
};

export const getDyPeo = async (huzhu) => {
  const pramsCopy = JSON.parse(JSON.stringify(prams));
  pramsCopy["isCommunist"] = 1;
  if (huzhu) {
    pramsCopy["huzhu"] = huzhu;
  }
  const data = await api.get("/api/h5/getCountryPopulationByHuzhu", {
    ...pramsCopy,
  });
  return data;
};

export const getYfdxPeo = async (huzhu) => {
  const pramsCopy = JSON.parse(JSON.stringify(prams));
  pramsCopy["isAssist"] = 1;
  if (huzhu) {
    pramsCopy["huzhu"] = huzhu;
  }
  const data = await api.get("/api/h5/getCountryPopulationByHuzhu", {
    ...pramsCopy,
  });
  return data;
};
//接种疫苗,废弃

export const GetALLCountryGridByGridname = async (params) => {
  const data = await api.get("/api/h5/GetALLCountryGridByGridname", {
    gridName: params.name,
  });
  const res = {};
  if (data && data[0]) {
    res.name = params.name;
    res.peoCount = data.length;
    res.memberName = data.find((x) => x.is_member == 1).name;
    res.manageName = data.find((x) => x.is_manage == 1).name;
  }
  return res;
};
export const getCountryGarbagedisposalinfosByName = async (params) => {
  const data = await api.get(
    "/api/h5/getCountryGarbagedisposalinfosByName",
    params
  );
  return data;
};
export const getCountryDomesticsewageinfosByName = async (params) => {
  const data = await api.get(
    "/api/h5/getCountryDomesticsewageinfosByName",
    params
  );
  return data;
};
export const getCountryToiletimprovementinfosByName = async (params) => {
  const data = await api.get(
    "/api/h5/getCountryToiletimprovementinfosByName",
    params
  );
  return data;
};
export const getCountryRefuseclassificationinfosByName = async (params) => {
  const data = await api.get(
    "/api/h5/getCountryRefuseclassificationinfosByName",
    params
  );
  return data;
};
export const getCountryHomesteadinfosByName = async (params) => {
  const data = await api.get("/api/h5/getCountryHomesteadinfosByName", params);
  return data;
};
export const getCountryContractedlandinfosByName = async (params) => {
  const data = await api.get(
    "/api/h5/getCountryContractedlandinfosByName",
    params
  );
  return data;
};
export const getCountryCollectivelandinfosByName = async (params) => {
  const data = await api.get(
    "/api/h5/getCountryCollectivelandinfosByName",
    params
  );
  return data;
};
export const getCountryCultivatedlandinfosByName = async (params) => {
  const data = await api.get(
    "/api/h5/getCountryCultivatedlandinfosByName",
    params
  );
  return data;
};
export const getCountryBasicfarmlandinfosByName = async (params) => {
  const data = await api.get(
    "/api/h5/getCountryBasicfarmlandinfosByName",
    params
  );
  return data;
};
export const getCountryGardeninformationinfosByName = async (params) => {
  const data = await api.get(
    "/api/h5/getCountryGardeninformationinfosByName",
    params
  );
  return data;
};
export const getCountryPopulationByName = async (params) => {
  const data = await api.get("/api/h5/getCountryPopulationByName", params);
  const data2 = await getCountryHomesteadinfosByName({
    name: data && data[0] && data[0].huzhu,
  });
  // for (let res1 of data1) {
  //   for (let res2 of data2) {
  //     if (res1.huzhu === res2.name) {
  //       res1 = { ...res2, ...res1 };
  //       break;
  //     }
  //   }
  // }

  data[0] = { ...data2[0], ...data[0] };
  return data;
};

//成虾销售经纪人
export const getShrimpSaleAgentByName = async (params) => {
  const data = await api.get("/api/h5/getShrimpSaleAgentByName", params);
  return data;
};
//虾苗经纪人
export const getShrimpAgentByName = async (params) => {
  const data = await api.get("/api/h5/getShrimpAgentByName", params);
  return data;
};
//虾苗企业/虾苗场
export const getShrimpEnterprisesByName = async (params) => {
  const data = await api.get("/api/h5/getShrimpEnterprisesByName", params);

  console.log(data);
  return data;
};
//水产加工企业
export const getShrimpOemByName = async (params) => {
  const data = await api.get("/api/h5/getShrimpOemByName", params);
  return data;
};
//塘口养殖信息
export const getShrimpPondCultureByName = async (params) => {
  const data = await api.get("/api/h5/getShrimpPondCultureByName", params);
  return data;
};
//随手拍
export const selectCoutrySnapshotListByState = async () => {
  const data = await api.get("/api/h5/selectCoutrySnapshotListByState", {
    stateOne: 0,
    stateTwo: 1,
    stateThree: 0,
  });
  return data;
};

//党组织
export const getDzz = async (params) => {
  const data = await api.get("/api/h5/getCountryPartyByName", params);
  return data;
};
//生态专用肥生产企业
export const getStzyfqy = async (params) => {
  const data = await api.get(
    "/api/h5/getShrimpFertilizerEnterprisesByName",
    params
  );
  return data;
};
//虾药品肥生产企业
export const getXypflqy = async (params) => {
  const data = await api.get("/api/h5/getShrimpDrugManufacturerByName", params);
  return data;
};
//饲料企业
export const getSlqy = async (params) => {
  const data = await api.get("/api/h5/getShrimpFeedEnterprisesByName", params);
  return data;
};
//学校废弃

//罗氏沼虾新闻
export const getRwly = async (params) => {
  const data = await api.get("/api/h5/GetAllShrimpJournalismList", params);
  return data;
};
//根据id获取设备当前检测数据
export const selectNewTimeData = async (params) => {
  const data = await api2.post("/system/data/selectNewTimeData", params);
  return data;
};
//根据id获取设备七天每天平均数据
export const selectWeekAvgData = async (params) => {
  const data = await api2.post("/system/data/selectWeekAvgData", params);
  return data;
};

//根据随手拍名字获取
export const getSspByName = async (params) => {
  const data = await selectCoutrySnapshotListByState();
  for (let res of data) {
    if (res.address == params.name) {
      return res;
    }
  }
  return {};
};
